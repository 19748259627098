import { useState } from "react";
import { TransactionsFilters } from "../models/transaction.model";
import { apiService } from "../services/api.service";
import Button from "./button";
import './table-export.scss';

interface TableExportProps {
    filters: Partial<TransactionsFilters>
}

const TableExport: React.FC<TableExportProps> = ({ filters }) => {
    const [ isLoading, setIsLoading ] = useState(false);
  
    const handleDownloadExcel = async () => {
        setIsLoading(true)
        try {
            const response = await apiService.generateExcel(filters);
            console.log(response);
            
            // if (response?.code === 'ECONNABORTED') {
            //     console.log('Request timed out');
            // } 
            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'transactions.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error: any) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    };

    return (
        <div className="table-export-container flex-row flex-align-end-center">
            <Button 
                theme='filters-button'
                onClick={handleDownloadExcel}
                label='Download Excel Export' 
                disabled={isLoading}
            />
        </div>
    );
};

export default TableExport;
