import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { attachAxiosLogInterceptors, attachAxiosApiInterceptors } from '../utils/axios-utils';
import { AccountTypeEnum, PaymentModel, RegisterAccount, UserModel, WalletCrypto, WalletInfo, WalletType } from '../models';
import { Dispatch } from '@reduxjs/toolkit';
import { AccountModel } from '../router/private/account-management/provider-list/provider-list-page';
import { ApiPageInfo } from './api.service-types';
import { TransactionsFilters } from '../models/transaction.model';

class ApiService {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      // #TODO add to config
      baseURL: 'https://bfireforge.realgrowsoft.com/',
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json',
      }
    });

  }

  attachDispatchToAxiosApi(dispatch: Dispatch<any>) {
    attachAxiosApiInterceptors(this.client, dispatch)
  }


  getUserDetails(userId: string): Promise<AxiosResponse<UserModel>> {
    return this.client.get(`/auth/${userId}`)

  }





  getAccountsByType(type: AccountTypeEnum, pageInfo: ApiPageInfo ) {
    return this.client.get(`account/getByType/${type}${this.getQueryParams(pageInfo)}`);
  }

  getAllAccounts(pageInfo: ApiPageInfo ): Promise<AxiosResponse<AccountModel[]>> {
    return this.client.get('account/getAllAccounts');
  }

  createAccount(account: RegisterAccount): Promise<AxiosResponse> {
    return this.client.post('account/createAccount', account);
  }

  checkWalletValidity(wallet: WalletInfo) {
    return this.client.post('wallet/checkWalletValidity', wallet)
  }

  getWalletByAccount(accountId: number) {
    return this.client.get(`wallet/getByAccountId/${accountId}`);
  }

  updateAccountStatus(id: number, status: string) {
    return this.client.put(`account/updateAccountStatusById/${id}/${status}`);
  }








  getAllWallets() {
    return this.client.get('wallet/getAllWallets');
  }

  getWalletsByTypeAndStatus(walletType: WalletType, status = 'ACTIVE') {
    return this.client.get(`/wallet/getByTypeAndStatus/${walletType}/${status}`)
  }

  getWalletBalance(address: string) {
    return this.client.get(`crypto/balanceByWallet/${address}`);
  }

  updateWalletStatus(id: number, status: string) {
    return this.client.put(`wallet/updateWalletStatusById/${id}/${status}`);
  }


  getTransactionsByWallet(walletAddress: string, pageInfo: ApiPageInfo ) {
    return this.client.get(`crypto/transactionsList/${walletAddress}${this.getQueryParams(pageInfo)}`);
  }

  getTransactionByHash(hash: string) {
    return this.client.get(`crypto/transactionByHash/${hash}`);
  }

  addTransactionDescription(description: string) {
    return this.client.post('crypto/addTransactionDescription', description);
  }

  getRecentTransactions() {
    return this.client.get('crypto/recentTransactions');
  }


  getWalletByTypeAndAsset(type: string, asset: string) {
    return this.client.get(`wallet/getByTypeAndAsset/${type}/${asset}`);
  }

  getWalletsByTypeAndAssetAndStatus(type: string, asset: string, status: string) {
    return this.client.get(`wallet/getByTypeAndAssetAndStatus/${type}/${asset}/${status}`)
  }

  createInternalWallet(wallet: WalletCrypto) {
    return this.client.post('wallet/createInternalWallet', wallet);
  }

  getTransactions(pageInfo: ApiPageInfo, filters: TransactionsFilters) {
    const pageParams = this.getQueryParams(pageInfo);
    const filterParams = this.getQueryParams(filters, false);

    const queryString = `${pageParams}${filterParams ? '&' + filterParams : ''}`;

    return this.client.get(`crypto/transactionsList${queryString}`);
  }

  getBalanceByInternalCurrency(asset: string) {
    return this.client.get(`crypto/balanceByInternalCurrency/${asset}`);
  }

  getTotalBalanceByManagementTypeInUsd(managementType: string) {
    return this.client.get(`crypto/totalBalanceByManagementTypeInUsd/${managementType}`);
  }

  getWalletsByTypeAndManagementType(walletType: WalletType, walletInternalType: string) {
    return this.client.get(`wallet/getByTypeAndManagementType/${walletType}/${walletInternalType}`);
  }

  getBalanceByManagementTypeInUsd(walletInternalType: string) {
    return this.client.get(`crypto/totalBalanceByManagementTypeInUsd/${walletInternalType}`)
  }






  getAllPaymentByUser() {
    return this.client.get(`/payment/getAllPaymentsByUser`);
  }


  getAllPayments() {
    return this.client.get('/payment/getAllPayments');
  }


  getPaymentsByUser() {
    return this.client.get(`payment/getPaymentApprovalsByUser/`);


  }

  createPayment(data: PaymentModel) {
    return this.client.post('payment/createPayment', data);
  }

  getPaymentDetails(id: string) {
    return this.client.get(`payment/getPayment/${id}`)
  }

  getPaymentApprovalsByPayment(paymentId: string) {
    return this.client.get(`payment/getPaymentApprovalsByPayment/${paymentId}`)
  }

  getAllPaymentsWhereUserIdIsApprover() {
    return this.client.get(`payment/getAllPaymentsWhereUserIdIsApprover/`)
  }

  updatePaymentApproval(id: string, status: string) {
    return this.client.put(`/payment/updatePaymentApproval/${id}/${status}`)
  }






  getQuarterlyTransactions(year: string) {
    return this.client.get(`/kpi/quarterlyTransactions/${year}`)
  }

  getAnuallyTransactions(year: string) {
    return this.client.get(`/kpi/annuallyTransactions/${year}`)
  }

  getTopProviders() {
    return this.client.get('/kpi/topProviders');
  }

  getTotalAssets() {
    return this.client.get('/kpi/totalAssets');
  };



  generateExcel(filters: Partial<TransactionsFilters>) {
    return this.client.get(`/documents/transactionExcel${this.getQueryParams(filters)}`, {
      responseType: "arraybuffer",
    })
  }

  private getQueryParams(params: any, addQuestionMark: boolean = true) {
    if (!params || typeof params !== 'object') {
      return '';
    }

    const queryString = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');

    return queryString && addQuestionMark ? `?${queryString}` : queryString;
  }

}

const apiService = new ApiService();
export { apiService };