import DoughnutChart from "./doughnut-chart";
import { ReactComponent as BitcoinRoundedIcon } from '../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthereumRoundedIcon } from '../assets/icons/ethereum-rounded.svg';
import { ReactComponent as USDTRoundedIcon } from '../assets/icons/usdt-rounded.svg';
import { ReactComponent as PositiveIcon } from '../assets/icons/positive.svg';
import { ReactComponent as NegativeIcon } from '../assets/icons/negative.svg';
import './total-assets-chart.scss';
import { TotalAssets } from "../models/statistics.model";

interface TotalAssetsChartProps {
    totalAssets: TotalAssets;
    isPercent?: boolean
}

const renderChangeIcon = (value?: number) => {
    if (value) {
        return value >= 0 ? <PositiveIcon /> : <NegativeIcon />;
    }
    return 0;
};

const TotalAssetsChart: React.FC<TotalAssetsChartProps> = ({ totalAssets, isPercent = false }) => {

    const { btcValueInUSD, usdtValueInUSD, ethValueInUSD, totalInUSD } = totalAssets;

    const getPercentage = (value: number, total: number) => {
        return total > 0 ? parseFloat(((value / total) * 100).toFixed(2)) : 0;
    };

    const assetsData = [
        { label: 'USDT', value: getPercentage(usdtValueInUSD, totalInUSD) },
        { label: 'BTC', value: getPercentage(btcValueInUSD, totalInUSD) },
        { label: 'ETH', value: getPercentage(ethValueInUSD, totalInUSD) },
    ]

    return (
        <div className="total-assets-container flex-row flex-gap-100 flex-align-start-center">
            <div className="doughnut-chart-container">
                <DoughnutChart data={assetsData} theme="assets" isPercent={isPercent}/>
            </div>
            <div className="assets-info flex-column flex-gap-30">
                <div className="asset-item flex-row flex-gap-40 flex-align-center-center">
                    <BitcoinRoundedIcon />
                    <span className="percentage">{totalAssets.btcValueEvolutionPercent?.toFixed(2)}%</span>
                    <div className="helper flex-row flex-gap-10">{renderChangeIcon(totalAssets.btcValueEvolutionPercent)} change over the last 24h</div>
                </div>
                <div className="asset-item flex-row flex-gap-40 flex-align-center-center">
                    <EthereumRoundedIcon />
                    <span className="percentage">{totalAssets.ethValueEvolutionPercent?.toFixed(2)}%</span>
                    <div className="helper flex-row flex-gap-10">{renderChangeIcon(totalAssets.ethValueEvolutionPercent)} change over the last 24h</div>
                </div>
                <div className="asset-item flex-row flex-gap-40 flex-align-center-center">
                    <USDTRoundedIcon />
                    <span className="percentage">{totalAssets.usdtValueEvolutionPercent?.toFixed(2)}%</span>
                    <div className="helper flex-row flex-gap-10">{renderChangeIcon(totalAssets.usdtValueEvolutionPercent)} change over the last 24h</div>
                </div>
            </div>
        </div>)
}

export default TotalAssetsChart;